
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, Select, MenuItem, Checkbox, Button } from '@mui/material';
import { StatsLabelButton } from './StatsLabelButton'
import { usePDF } from 'react-to-pdf';
import * as XLSX from 'xlsx';

const PREFIX = 'StatsPopup';

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    header: `${PREFIX}-header`,
    headerText: `${PREFIX}-headerText`,
    tableCell: `${PREFIX}-tableCell`,
    buttonRow: `${PREFIX}-buttonRow`,
    button: `${PREFIX}-button`
};

const StyledModal = styled(Modal)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    maxWidth: "1200px",
    margin: "auto",
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '80%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        maxHeight: '98%'
    },
    [`& .${classes.table}`]: {
        // margin: '1rem 0 1rem 0'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0'
    },
    [`& .${classes.tableCell}`]: {
        fontSize: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 2rem 0 2rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.5rem'
    },
    [`& .${classes.button}`]: {
        margin: '0 0.25rem 0 0.25rem'
    }
});

function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

const averageAll = async (sortFn, averageFn, contentLength, dataKeys, chartPrecision) => {
    const dataArr = await Promise.all([...Array(contentLength.length || 1).keys()].map(dataIndex => sortFn({ resort: true, dataOnly: true, dataIndex })))
    const baseData = await Promise.all(dataArr.map((data, i) => averageFn(chartPrecision ? data.resampledData : data.timechart, [0, chartPrecision ? Math.round(contentLength[i] / 1000) : contentLength[i] / 1000], dataKeys)))
    return { totals: baseData, buttons: dataArr.map(data => chartPrecision ? data.resampledTuneOuts : data.tuneOuts) }
}

const displayTotal = (virtual, tuneOut, total, allTuneOuts) => {
    if (!!virtual && virtual.dataKey === 'button' && virtual.dataValue === 'R') {
        return virtual.condition === '!==' ? (total - allTuneOuts) : tuneOut
    } else {
        return total
    }
}

export const StatsPopup = React.memo(({ open, prefix, loaded, handleClose, contentLength = [], averageFn, sortFn, showAll, dataKeys, virtualDataKeys, scorePrecision, chartPrecision, respondents, count, colors }) => {


    const [totals, setTotals] = useState([])
    const [buttons, setButtons] = useState([])

    const { toPDF, targetRef } = usePDF({ filename: `${prefix} Statistics.pdf`, page: { orientation: 'landscape' } });

    useEffect(() => {
        if (averageFn && sortFn) {
            averageAll(sortFn, averageFn, contentLength, dataKeys, chartPrecision).then(totalsObj => {
                setTotals(totalsObj.totals)
                setButtons(totalsObj.buttons)
            })
        }
    }, [averageFn, sortFn, contentLength, dataKeys, chartPrecision]);

    const handleExportXLSX = useCallback(() => {
        const workbook = XLSX.utils.table_to_book(targetRef.current, { sheet: 'Statistics', raw: true });
        XLSX.writeFile(workbook, `${prefix} Statistics.xlsx`);
    }, [prefix])

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            // closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                    sx: { zIndex: -1 }
                }
            }}
            disablePortal
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <div className={classes.header}>
                        <Typography variant="h5" className={classes.headerText}>Overall Stats</Typography>
                    </div>
                    <TableContainer className={classes.table} component={Paper}>
                        <Table size="small" ref={targetRef}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Segment</TableCell>
                                    <TableCell>Statistic</TableCell>
                                    {contentLength.map((x, i) => <TableCell>Video {i + 1}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataKeys.filter(key => !!count[key] && (showAll || key !== 'all')).map((key) => (<>
                                    <TableRow>
                                        <TableCell rowSpan={2}>
                                            <div className={classes.tableCell}>
                                                <StatsLabelButton dataKey={key.split(" + ")} disabled={true} selected={true} activeColor={colors[key] || colors["default"]} unselectedColor={colors[key] || colors["default"]} textColor={getContrastYIQ(colors[key] || colors["default"])} />
                                            </div>
                                        </TableCell>
                                        <TableCell>Dial</TableCell>
                                        {contentLength.map((x, i) => <TableCell>{(!isNaN(totals[i]?.[key]) && totals[i]?.[key] !== null) ? (Math.round((totals[i]?.[key] * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)) : '\u2012'}</TableCell>)}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tune Outs</TableCell>
                                        {contentLength.map((x, i) => {
                                            const tuneOut = (buttons[i]?.[key] && buttons[i]?.[key].reduce((p, c) => p + c.tuneOuts, 0)) || 0
                                            const allTuneOuts = (buttons[i]?.['all'] && buttons[i]?.['all'].reduce((p, c) => p + c.tuneOuts, 0)) || 0
                                            const total = displayTotal(virtualDataKeys[key], tuneOut, count[key], allTuneOuts)
                                            return <TableCell>{tuneOut}/{total}</TableCell>
                                        })}
                                    </TableRow>
                                </>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.buttonRow}>
                        <Button color="primary" variant='contained' className={classes.button} onClick={handleExportXLSX}>EXPORT XLSX</Button>
                        <Button color="primary" variant='contained' className={classes.button} onClick={toPDF}>EXPORT PDF</Button>
                    </div>
                </Paper>
            </Fade>
        </StyledModal>
    );

})